@use "mixins" as *;
.color__container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: var(--radius);
    border: .4px solid var(--color-border-tertiary);
    padding-inline: 0;
    padding-block: 0;
}

.color__button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.1rem;
    height: 2.1rem;
    padding-inline: 0;
    padding-block: 0;
    border-radius: 999px;

    &:hover {
        background-color: transparent;
    }
}

.color {
    display: flex;
    justify-content: center;
    align-items: center;
    width: .6rem;
    height: .6rem;
    border-radius: 999px;

    &.-choice {
        width: 1.8rem;
        height: 1.8rem;
    }
}

.color__icon {
    display: none;
    width: 1.4rem;
    height: 1.4rem;
    color: var(--color-text-quaternary);

    &.-choosen {
        display: block;
    }
}
.color__modal {
    position: absolute;
    transform: translate(-46.5%, 2.8rem);
    display: flex;
    flex-direction: row;
    gap: .4rem;
    padding: .4rem;
    border-radius: var(--radius);
    background-color: var(--content-bg--color-light);
    z-index: 999;
    border: .4px solid var(--color-border-secondary);

    &.-hidden {
        display: none;
    }
}
